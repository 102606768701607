// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCqnRyrwvnPX8-tSIRKnN9iEQ2kB8GG5_8",
    authDomain: "fishnet-88c3e.firebaseapp.com",
    projectId: "fishnet-88c3e",
    storageBucket: "fishnet-88c3e.appspot.com",
    messagingSenderId: "634482270371",
    appId: "1:634482270371:web:807f82b0337e787ef21f16",
    measurementId: "G-1DGFYRLY84",
    vapidKey:'BN-tfsF9gg9Ln0Z1JF-2ZKtKpe647P2BOzwU3L66LqeNSOY1iy1QVx6seqDxob-ZQ1ij7yQ0JxMjfyXBRSt8TFw'
  },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
