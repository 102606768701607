import { Component, OnInit, Input, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/appSettings';
import { Share } from '@capacitor/share';
import domtoimage from 'dom-to-image';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import {StorageService} from '../../../services/storage-service/storage.service';
import { SessionInfoServiceService } from 'src/app/services/session-info-service/session-info-service.service';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { ImageModalComponent } from 'src/app/modals/image-modal/image-modal.component';
// import { PostCommentCardComponent } from '../post-comment-card/post-comment-card.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
})
export class PostCardComponent implements OnInit {


  @Input() post_info;
  private API_ENDPOINT=AppSettings.API_ENDPOINT;
  private POST_ENDPOINT="post-info/";
  private COMMENT_ENDPOINT="comment-info/";
  public comments=[];
  public displayedComments=[];
  public initialCommentsToShow = 3;
  private comment_count=0;
  public showAllComments=false;
  public task: string;
  public post_id;
  post_liked = false;
  sessionInfo: any;


  public imageTypes = ['jpeg',  'png', 'gif', 'tiff', 'psd', 'eps', 'raw', 'heic', 'webp','jpg'];

  public videoTypes = ['webm', 'mpg', 'mp2', 'mp4', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'avi', 'wmv', 'mov', 'flv'];

  constructor(
    private http: HttpClient,
    private ss: StorageService,
    private sessionInfoService: SessionInfoServiceService,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private router: Router
  ) { }

  ngOnInit() {
    this.sessionInfoService.getSessionInfo().then((session_info) => {
      this.sessionInfo = session_info;
      this.post_id = this.post_info["id"];
      this.getComments();
      this.post_liked = this.post_info.users_like.some(el => el.user_id === this.sessionInfo["id"]);
    });
  }

  async getComments(){
    await this.http.get(this.API_ENDPOINT + this.COMMENT_ENDPOINT + "get-post-comments/"+this.post_info["id"]).subscribe(
      data=>{
        this.comments=data["data"];
        this.comment_count=this.comments.length;
        this.updateDisplayedComments();
      },
      error=>{
        console.log(error);
      }
    );
  }

  showAllClicked(){
    this.showAllComments= !this.showAllComments;
    this.updateDisplayedComments();
  }

  async likePost(){
    let headers = new HttpHeaders();
    let postData = {
      post_id: this.post_info["id"],
      user_id: this.sessionInfo["id"]
    };
    await this.http.post(this.API_ENDPOINT + this.POST_ENDPOINT + 'like-post/', JSON.stringify(postData)).subscribe(
      data=>{
        if (data["status"]==200){
          console.log(data);
          this.post_liked = data["data"]["you_liked"];
          this.post_info["likes"] = (parseInt(data["data"]["likes"])).toString();
        }
      },
      error=>{
        console.log(error);
      });
  }

  async postComment(){
    return;
    this.task = (document.getElementById(`task-${this.post_id}`) as HTMLInputElement).value;
    if (this.task) {
      let data={
        post_id: this.post_id,
        user_id: this.sessionInfo['id'],
        content: this.task
      };

      this.http.post(this.API_ENDPOINT + this.COMMENT_ENDPOINT +'create-post-comment', JSON.stringify(data)).subscribe(
        res=>{
          if (res['status'] ==='200'){
            console.log(res);
            this.task = '';
          } else {
            console.log(res);
            this.task = '';
          }
          this.getComments();
        },
        err=>{
          console.log(err);
        }
      );
    }
  }


  async shareImage() {
    const deepLink = `https://fishnet.com.hk/forum?post_id=` + this.post_info.id;
    Share.share({
      title: this.post_info['display_name'],
      text: deepLink,
      // url: encodedDeepLink,
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing ::: ', error));
  }

  // async showMedia(link) {
  //   window.open(link);
  // }

  async showMedia(imageUrl: string) {
    const modal = await this.modalController.create({
      component: ImageModalComponent,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: {
        imageUrl: imageUrl
      },
    });

    return await modal.present();
  }

  checkMediaTypeFromLink(link) {
    const type = link.split('.')[link.split('.').length - 1];
    if (this.imageTypes.includes(type)) {
      return true;
    } else if (this.videoTypes.includes(type)) {
      return false;
    }
  }

  updateDisplayedComments() {
    if (this.showAllComments) {
      this.displayedComments = this.comments;
    } else {
      this.displayedComments = this.comments.slice(0, this.initialCommentsToShow);
    }
  }
}
