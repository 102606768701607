import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent {
  @Input() imageUrl: string;


  public imageTypes = ['jpeg',  'png', 'gif', 'tiff', 'psd', 'eps', 'raw', 'heic', 'webp','jpg'];

  public videoTypes = ['webm', 'mpg', 'mp2', 'mp4', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'avi', 'wmv', 'mov', 'flv'];

  constructor(
    private modalController: ModalController,
    private sanitizer: DomSanitizer
  ) {}

  checkMediaTypeFromLink(link) {
    const type = link.split('.')[link.split('.').length - 1];
    if (this.imageTypes.includes(type)) {
      return 'image';
    } else if (this.videoTypes.includes(type)) {
      return 'video';
    } else {
      return 'doc';
    }
  }

  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalController.dismiss(closeModal);
  }
}
