import {Component, ElementRef, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import { AppSettings } from 'src/appSettings';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UploadPhotoFilterPage} from '../../../modals/upload-photo-filter/upload-photo-filter.page';
import {IonRouterOutlet, ModalController} from '@ionic/angular';
import {StorageService} from '../../../services/storage-service/storage.service';
import { SessionInfoServiceService } from 'src/app/services/session-info-service/session-info-service.service';
import { UserService } from 'src/app/services/user-service/user.service';


@Component({
  selector: 'app-forum',
  templateUrl: './forum.page.html',
  styleUrls: ['./forum.page.scss'],
})
export class ForumPage implements OnInit, AfterViewChecked {
  private api_url = AppSettings.API_ENDPOINT;

  private post_url='post-info/';
  private comment_url='comment-info/';
  public posts=[];
  private searched_posts=[];
  private all_posts=[];

  public pl=[];
  private url=[];
  public thumbnailDataUrl: string = null;
  private modalDataResponse: any;
  private numberOfImage = 0;
  private numberOfVideo = 0;
  public showTextBox: boolean;
  public task: string;
  private sending = false;
  private profile = {};
  private sessionInfo;
  public searchInput;
  private postId: string;
  private focusedPostId: string;
  private shouldScroll: boolean = true;
  private searchSubject = new Subject<string>();
  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;
  @ViewChild('postCards', { read: ElementRef }) postCards: ElementRef;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private http: HttpClient,
    private ss: StorageService,
    private sessionInfoService: SessionInfoServiceService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    this.showTextBox = false;
    this.profile = await this.userService.getUserProfile();
    console.log(this.profile);
    this.sessionInfoService.getSessionInfo().then((session_info) => {
      this.sessionInfo = session_info;
    });
    this.setupSearchListener();

    this.route.queryParams.subscribe(params => {
      if (params['post_id']) {
        this.postId = params['post_id'];
      }
    });
    await this.getAllPosts();
    alert('暫未開放');
  }

  ngAfterViewChecked() {
    this.scrollToPost();
  }

  async setShowTextBox() {
    this.showTextBox = !this.showTextBox;
  }

  async getAllPosts() {
    await this.http.get(this.api_url + this.post_url + 'all-posts').subscribe(
          (data) => {
            this.all_posts = [];
            for (const i of data['data'].reverse()) {
              let media = JSON.parse(i.media.replace(/'/g, '"'));
              i.media = media;
              this.all_posts.push(i);
            }
            this.posts = this.all_posts;
          },
          (error) => console.log(error)
        );
  }

  loadImageFromDevice(event) {
    const file = event.target.files[0];
    if (file) {
      // console.log(this.file)
      if (file.type.split('/')[0]==='image'){
        this.numberOfImage++;
        if (this.numberOfImage > 9) {
          alert('圖片上限為9個。');
          this.numberOfImage--;
          return;
        }
        const reader=new FileReader();
        reader.readAsDataURL(file);
        reader.onload=(event)=>{
          const img = new Image();
          img.src = event.target.result as string;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const maxImageWidth = 300;
            const scaleFactor = maxImageWidth / img.width;
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const resizedImageDataURL = canvas.toDataURL('image/jpeg', 0.7);
            this.pl.unshift(JSON.stringify(resizedImageDataURL));
            this.url.unshift(resizedImageDataURL);
          };
        };
      }
      else{
        alert('請選擇圖像文件。');
      }
    }

    console.log(file);
  }

  loadVideoFromDevice(event) {
    const file = event.target.files[0];
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > 5) {
        alert('視頻文件大小不能超過5MB。');
        return;
      }
      if (file.type.split('/')[0]==='video'){
        this.numberOfVideo++;
        if (this.numberOfVideo > 1) {
          alert('視頻上限為1個。');
          this.numberOfVideo--;
          return;
        }

        const reader=new FileReader();
        reader.readAsDataURL(file);
        reader.onload=(event)=>{
          const video = this.videoPlayer.nativeElement;
          video.src = reader.result as string;
          video.onloadedmetadata = () => {
            video.currentTime = 1;
            video.onseeked = () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              const context = canvas.getContext('2d');
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              console.log(canvas.toDataURL('image/jpeg'));
              this.thumbnailDataUrl = canvas.toDataURL('image/jpeg');
            };
          };
          this.pl.unshift(JSON.stringify(event.target.result));
          this.url.unshift(reader.result);
        };
      }
      else{
        alert('請選擇一個視頻文件。');
      }
    }
  }

  postToServer(){
    return;
    if (!this.sending && this.task) {
      this.sending = true;
      this.task = (document.getElementById('task') as HTMLInputElement).value;
      let data={
        user_id: this.profile['id'],
        content: this.task,
        media: JSON.stringify(this.pl)
      };

      let headers = new HttpHeaders();
      this.http.post(this.api_url+ 'post-info/' +'post', JSON.stringify(data), {headers: headers}).subscribe(
        res=>{
          if (res['status']==200){
            this.all_posts.unshift({
              avatar_url: this.profile['avatar_url'],
              content: this.task,
              datetime: new Date().toJSON().slice(0, 10),
              display_name: this.profile['display_name'],
              id: res['data'][0].id,
              likes: '0',
              media: JSON.parse(res['data'][0].media),
              user_id: this.profile['id'],
            });
            console.log(res);
            this.pl = [];
            this.url = [];
            this.task = '';
            this.sending = false;
            this.posts = this.all_posts;
            this.numberOfImage = 0;
            this.numberOfVideo = 0;
          }
        },
        err=>{
          console.log(err);
          alert('Posting is failed');
          this.sending = false;
        }
      );
    }
  }

  deleteP(i){
    if(this.pl[i].includes('image')) {
      this.numberOfImage--;
    } else if (this.pl[i].includes('video')) {
      this.numberOfVideo--;
    }
    this.pl.splice(i, 1);
    this.url.splice(i, 1);
  }

  drop(event: Event)  {
    if (this.isDragDrop(event)) {
      moveItemInArray(this.pl, event.previousIndex, event.currentIndex);
    }
  }

  isDragDrop(object: any): object is CdkDragDrop<any[]> {
    return 'previousIndex' in object;
  }

  async presentUploadPhotoFilterModal(i){
    const modal = await this.modalController.create({
      component: UploadPhotoFilterPage,
      componentProps: {
        url: this.url[i],
      },
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.modalDataResponse = modalDataResponse.data;
        // console.log('Modal Sent Data : '+ modalDataResponse.data);
        this.pl[i] = this.modalDataResponse;
        this.url[i] = this.modalDataResponse;
      }
    });

    return await modal.present();
  }

  setupSearchListener() {
    this.searchSubject
      .pipe(
        debounceTime(300), // Adjust the debounce time as needed
        distinctUntilChanged(),
        switchMap(keyword => this.searchPosts(keyword))
      )
      .subscribe((data: any) => {
        this.searched_posts = [];
        for (var i of data['data'].reverse()){
          let media = JSON.parse(i.media.replace(/'/g, '"'));
          i.media = media;
          this.searched_posts.push(i);
        }
        this.posts = this.searched_posts;
      });
  }

  searchInputted(event: any) {
    const keyword = event.detail.value;
    this.searchSubject.next(keyword);
  }

  searchPosts(keyword: string) {
    if (keyword) {
      const postData = {
        user_id: this.sessionInfo['id'],
        keyword: keyword,
      };
      return this.http.post(this.api_url + this.post_url + 'search-post/', JSON.stringify(postData));
    } else {
      this.posts = this.all_posts;
      return [];
    }
  }

  checkMediaTypeFromBase64(base64Data) {
    const mediaType = base64Data.split(',')[0].split(':')[1].split(';')[0];
    if (mediaType.includes('image/')) {
      return true;
    } else if (mediaType.includes('video/')) {
      return false;
    }
  }

  async scrollToPost() {
    if (this.shouldScroll) {
      const elementId = 'app-post-' + this.postId;
      const postCardElement = this.postCards.nativeElement.querySelector(`#${elementId}`);
      if (postCardElement) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        try {
          postCardElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        } catch (error) {
          postCardElement.scrollIntoView();
        }
        this.focusedPostId = elementId;
        setTimeout(() => {
          this.focusedPostId = null;
          this.shouldScroll = false;
        }, 5000);
      }
    }
  }
}
