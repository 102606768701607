import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ActivatedRoute} from '@angular/router';
import { ProductCreateService } from 'src/app/services/product-create/product-create.service';
import { ProductInfoService } from 'src/app/services/product-info/product-info.service';
import { AppSettings } from 'src/appSettings';


@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() product_info = {};
  @Input() parent_url;
  @Input() urlType=null;

  imageTypes = ['jpeg',  'png', 'gif', 'tiff', 'psd', 'eps', 'raw', 'heic', 'webp','jpg'];

  videoTypes = ['webm', 'mpg', 'mp2', 'mp4', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'avi', 'wmv', 'mov', 'flv', 'quicktime'];
  firstScreenType: any;
  combinedHashtags: string = '';
  private discounted_price;
  private image_url;
  private product_id;
  private current_url;
  private url_type;

  public hashtag_array = []

  public api_url = AppSettings.API_ENDPOINT;

  public isSellerProductOutOfStock: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productInfoService: ProductInfoService,
    private http: HttpClient,
    private productCreateService: ProductCreateService
  ) { }


  ngOnInit() {

    this.getAllHashtag();
    this.discounted_price = Math.round( ((100- this.product_info["discount"]) * 0.01 * this.product_info["price"]) * 10 ) /10 ;
    this.product_info['discounted_price'] = this.discounted_price;
    // console.log(this.discounted_price);
    const firstImage = this.product_info['media'].find(el => this.checkMediaTypeFromLink(el) === 'image');
    if (firstImage) {
      this.checkAssetType(firstImage);
    }
    this.current_url = this.router.url;

    if (this.product_info['stock'] === '0' && this.parent_url.includes('seller')){
      this.isSellerProductOutOfStock = true;
      console.log("Seller product is out of stock");
    }
    this.url_type = this.urlType;

    // Future parent url will be the current url
    // this.parent_url = this.router.url;
  }


  async getAllHashtag() {
    const payload = {
      productId: this.product_info["id"]
    };

    try {
      const response = await this.http.post(this.api_url + 'product-info/all-hashtag-by-product-id', JSON.stringify(payload)).toPromise();
      this.hashtag_array = response["data"];
      this.combineHashtags();
    } catch (error) {
      console.log(error);
    }
  }

  async openProductPage() {
    console.log("Image Url is", this.image_url)
    this.product_id = this.product_info["id"];
    this.productInfoService.setProductInfo(this.product_info);
    if (this.parent_url.includes('seller')) {
      await this.router.navigate(['/seller/product', this.product_id], { state: { parent_url: this.parent_url }});
    } else {
      this.registerProductClick(this.product_id);
      await this.router.navigate(['/product', this.product_id], { state: { parent_url: this.parent_url,url_type:this.url_type }});
    }
  }

  registerProductClick(product_id){
    this.productCreateService.updateProductClick(product_id);
  }

  async checkAssetType(url) {
    if(typeof(url) === 'string'){
      const type = url.split('.')[url.split('.').length - 1];
      if (this.imageTypes.includes(type)) {
        this.firstScreenType = 'image';
        this.image_url = url;
      } else if (this.videoTypes.includes(type)) {
        this.firstScreenType = 'video';
        this.image_url = url;
      }

    }
    else {
      // console.log('ERROR');
    }

  }

  checkMediaTypeFromLink(link) {
    try {
      const type = link.split('.')[link.split('.').length - 1];
      if (this.imageTypes.includes(type)) {
        return 'image';
      } else if (this.videoTypes.includes(type)) {
        return 'video';
      }
    } catch (e) {
      console.log(e);
    }
    return 'nothing';
  }

  truncateString(str: string, maxLength: number): string {
    if (!str || str.length <= maxLength) {
      return str;
    }
    return str.substring(0, maxLength) + '...';
  }

  combineHashtags() {
    this.combinedHashtags = this.hashtag_array.map((hashtag, index) => {
      const nextHashtag = this.hashtag_array[index + 1];
      return nextHashtag ? `${hashtag.hashtag} / ${nextHashtag.hashtag}` : null;
    }).join(''); // 选择合适的分隔符，示例中使用 ", "
  }
}

