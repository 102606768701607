import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    return this._storage?.set(key, value);
  }
  public get(key: string) {
    return this._storage?.get(key);
  }

  async setObject(key, value) {
    await localStorage.setItem(
      key,
      JSON.stringify(value)
    );
  }

  async getObject(key){
    const ret = await localStorage.getItem(key);
    return JSON.parse(ret);
  }
}
