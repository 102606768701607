import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {PreloadAllModules, RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import { HttpClientModule} from '@angular/common/http';
import { PinchZoomDirective } from './components/directives/pinch-zoom.directive';
// import { Facebook } from '@ionic-native/facebook/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ComponentsModule } from './components/components.module';
import {ForumPageModule} from './pages/buyer/forum/forum.module';


const routes: Routes = [
  { path: 'forum', component: ForumPageModule }
];

@NgModule({
  declarations: [AppComponent, PinchZoomDirective],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    // Ionic4EmojiPickerModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    IonicStorageModule.forRoot()],
  providers: [
    // FCM,
    InAppBrowser,
    Deeplinks,
    // Facebook,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
// "ANDROID_DEFAULT_NOTIFICATION_ICON": "@mipmap/ic_launcher",
// "ANDROID_FCM_VERSION": "21.0.0",
// "ANDROID_FIREBASE_BOM_VERSION": "26.0.0",
// "ANDROID_GOOGLE_SERVICES_VERSION": "4.3.4",
// "ANDROID_GOOGLE_SERVICES_VERSION": "4.3.4",
