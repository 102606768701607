import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateUnreadChatService {

  private refreshSubject = new BehaviorSubject<boolean>(false);

  /** 设置刷新状态 */
  triggerRefresh() {
    this.refreshSubject.next(true);
  }

  /** 订阅刷新状态 */
  getRefreshObserver() {
    return this.refreshSubject.asObservable();
  }
}
