import { Directive, ElementRef, OnInit } from '@angular/core';
import * as Hammer from 'hammerjs';

@Directive({
  selector: '[appPinchZoom]'
})
export class PinchZoomDirective implements OnInit {
  private element: HTMLElement;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const hammer = new Hammer(this.element);

    let scale = 1;
    hammer.get('pinch').set({ enable: true });

    hammer.on('pinchmove', (ev) => {
      this.element.style.transform = `scale(${scale * ev.scale})`;
    });

    hammer.on('pinchend', (ev) => {
      scale *= ev.scale;
    });
  }
}
