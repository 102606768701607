import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {AppSettings} from "../../../appSettings";
import { App } from '@capacitor/app';
import {SessionInfoServiceService} from "../session-info-service/session-info-service.service";
// import {
//   Plugins,
//   PushNotification,
//   PushNotificationToken,
//   PushNotificationActionPerformed,
//   Capacitor
// } from '@capacitor/core';



@Injectable({
  providedIn: 'root'
})
export class FcmService {
  private currentToken = '';
  private api_endpoint = AppSettings.API_ENDPOINT;
  private sessionInfo;
  appIsActive: boolean = true;
  constructor(private router: Router,
              private http: HttpClient,
              private sessionInfoService: SessionInfoServiceService,

  ) { }

  async ngOnInit() {
    this.sessionInfo = await this.sessionInfoService.getSessionInfo();
    // console.log('app comment initializeApp:---->>>>');
    // console.log('app comment initializeApp11:---->>>>',this.sessionInfo['id']);
    //
    // this.sendTokenToServer(this.sessionInfo['id']);
  }

  initPush() {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush();
    }
  }

  getFCMToken(): string {
    return this.currentToken;
  }

  public async registerPush() {

    console.log('开始注册推送...');
    // Push Notification Registration
    let permStatus = await PushNotifications.checkPermissions();
    console.log('权限状态: ', permStatus);

    if (permStatus.receive === 'prompt') {
      console.log('请求权限...');

      permStatus = await PushNotifications.requestPermissions();
      console.log('permStatus22'+ JSON.stringify(permStatus) );
    }

    // if (permStatus.receive !== 'granted') {
    //   console.error('用户拒绝了权限!');
    //   throw new Error('User denied permissions!');
    // }

    if (permStatus.receive === 'denied') {
      console.error('用户拒绝了权限!');
      throw new Error('User denied permissions!');
    }
    console.log('权限已授予，注册推送...');

    await PushNotifications.register();

    console.log('添加推送通知监听...');
    // Push Notification Event Listeners
    await PushNotifications.addListener('registration', (token) => {
      console.log('获取到的 token: ' + JSON.stringify(token));

        this.currentToken = token.value;
      console.log('当前 token: ' + this.currentToken);

      }
    );

    await PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
      console.log('My token333344444: ' );
      console.error('注册错误: ' + JSON.stringify(error));
    });

    await PushNotifications.addListener(
      'pushNotificationReceived', async (notification) => {
        console.log('Push received: ' + JSON.stringify(notification));
        console.log('接收到的推送: ' + JSON.stringify(notification));
        this.handleNotification(notification);
      }
    );


    await PushNotifications.addListener(
      'pushNotificationActionPerformed', async (notification) => {
        const data = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(notification.notification));
        console.log('处理的推送操作: ' + JSON.stringify(notification));
        if (data.page === 'product') {
          await this.router.navigate(['/product', data.id], { state: { parent_url: '/homepage-new' }, queryParams: {id: data.id}});
        }
        if (data.page === 'chat') {
          if (data.sender === 'customer') {
            await this.router.navigate(['/seller/chatroom']);
          } else {
            await this.router.navigate(['/chatroom']);
          }
        }
        if (data.detailsId) {
          this.router.navigateByUrl(`/homepage-new`);
        }
        if (data.page === 'order') {

          // await this.router.navigate(['/order-detail', { id: data.orderId }]);
          this.router.navigateByUrl(`/homepage-new`);
        }
      }
    );

    App.addListener('appStateChange', (state) => {
      console.log('App state changed. Is app active?', state.isActive);
      if (state.isActive) {
        // 前台状态
        // 处理您准备用于在前台设置的任何逻辑
      } else {
        // 后台状态
        // 这里可以设定在应用进入后台时处理的逻辑
      }
    });

  }


  handleNotification(notification) {
    // 根据应用状态处理通知显示
    if (this.appIsActive) {
      // 应用在前台，自行处理如何展示通知
      alert(notification.title + ': ' + notification.body);
    } else {
      // 应用在后台或已关闭，系统会处理显示通知
      // 此处可以选择添加额外的逻辑，比如将通知内容保存到本地等
    }
  }

   sendTokenToServer(user_id) {
     console.log('fcmccc initializeAppccc:---->>>>',user_id);
     console.log('this.currentToken initializeAppvvvv:---->>>>',this.currentToken);
    if (this.currentToken) {
      const postData = {
        token: this.currentToken,
        user_id: user_id
      };
      console.log('postData initializeApp:---->>>>',JSON.stringify(postData));
      this.http.post(this.api_endpoint+'chat-info/save-fcm-token', JSON.stringify(postData)).subscribe(
        data=> {
          console.log('Token sent to server:', data);
        },
        error=>{
          console.log(error);
          console.error('Error sending token to server:', JSON.stringify(error));
        }
      );
    } else {
      console.warn('No FCM token available');
    }
  }
}
