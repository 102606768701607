
import {Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { ImageCropperComponent, ImageTransform, ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-upload-photo-filter',
  templateUrl: './upload-photo-filter.page.html',
  styleUrls: ['./upload-photo-filter.page.scss'],
})
export class UploadPhotoFilterPage implements OnInit {
  adjustment:number = 1;

  moreDetail: boolean = false;

  canvasRotation = 0;
  transform: ImageTransform = {};
  imageFilter: string = '';
  cumulativeFilter: string = '';
  adjustments: Record<string, number> = {};
  private croppedImage: string;

  @ViewChild(ImageCropperComponent, { static: false}) angularCropper: ImageCropperComponent;
  @Output() modifiedImageEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private modalController: ModalController,

  ) {}

  ngOnInit() {
    this.captureImage();
  }

  openLessDetail(){
    this.moreDetail = false;
  }

  openMoreDetail(){
    this.moreDetail = true;
  }

  brightness() {
    this.adjustment = 1;
  }

  contrast() {
    this.adjustment = 2;
  }

  highlights() {
    this.adjustment = 3;
  }

  shadows() {
    this.adjustment = 4;
  }

  saturation() {
    this.adjustment = 5;
  }

  temperature() {
    this.adjustment = 6;
  }

  exposure() {
    this.adjustment = 7;
  }

  async close() {
    await this.modalController.dismiss(this.croppedImage);
  }

  myImage = null;
  url: string;

  captureImage() {
    this.myImage = this.url;
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
    this.getModifiedImageBase64();
}


  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
}

  flipHorizontal() {
  this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
  };
    this.getModifiedImageBase64();
}

  flipVertical() {
  this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
  };
    this.getModifiedImageBase64();
}

  reset() {
    this.canvasRotation = 0;
    this.transform = {};
    this.imageFilter = '';
  }

  convertFileToDataURLviaFileReader(url: string) {
    return Observable.create(observer => {
      let xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onload = function() {
        let reader: FileReader = new FileReader();
        reader.onloadend = function() {
          observer.next(reader.result);
          observer.complete();
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  adjustValue(event: any) {
    const value = event.detail.value + 100;
    switch (this.adjustment) {
      case 1:
        this.adjustments['brightness'] = value;
        break;
      case 2:
        this.adjustments['contrast'] = value;
        break;
      case 3:
        this.adjustments['highlights'] = value;
        break;
      case 4:
        this.adjustments['shadows'] = value;
        break;
      case 5:
        this.adjustments['saturation'] = value;
        break;
      case 6:
        this.adjustments['temperature'] = value;
        break;
      case 7:
        this.adjustments['exposure'] = value;
        break;
      default:
        // Do nothing for unknown adjustment types.
        break;
    }
    this.applyFilter();
    this.getModifiedImageBase64();
  }

  private applyFilter(): void {
    const filterValues: string[] = [];
    for (const adjustmentType in this.adjustments) {
      const value = this.adjustments[adjustmentType];
      let filterValue = '';
      switch (adjustmentType) {
        case 'brightness':
          const brightnessValue = value * 2 - 100;
          filterValue = `brightness(${brightnessValue}%)`;
          break;
        case 'contrast':
          const contrastValue = value * 2 - 100;
          filterValue = `contrast(${contrastValue}%)`;
          break;
        case 'highlights':
          const highlightsValue = value * 2 - 100;
          filterValue = `brightness(${highlightsValue}%)`;
          break;
        case 'shadows':
          const shadowsValue = value * 2 - 100;
          filterValue = `brightness(${shadowsValue}%)`;
          break;
        case 'saturation':
          const saturationValue = value * 2 - 100;
          filterValue = `saturate(${saturationValue}%)`;
          break;
        case 'temperature':
          const temperatureValue = value * 2 - 100;
          filterValue = `sepia(${temperatureValue}%)`;
          break;
        case 'exposure':
          const exposureValue = value * 2 - 100;
          filterValue = `brightness(${exposureValue}%)`;
          break;
        default:
          continue;
      }
      filterValues.push(filterValue);
    }
    this.imageFilter = filterValues.join(' ');
  }

  private async getModifiedImageBase64() {
    if (!this.croppedImage) {
      console.error('No cropped image data available.');
      return;
    }

    const img = document.getElementsByClassName('ngx-ic-source-image')[0] as HTMLImageElement;
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.filter = this.imageFilter;
    ctx.drawImage(img, 0, 0);
    this.croppedImage = canvas.toDataURL('image/jpeg');
    this.modifiedImageEvent.emit(this.myImage);
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
}
