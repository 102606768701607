import { Component, OnInit , OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {AppSettings} from "../../../appSettings";
import { log } from 'console';
import {SessionInfoServiceService} from "../../services/session-info-service/session-info-service.service";
import {UpdateUnreadChatService} from "../../services/chat/update-unread-chat.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toolbar-seller',
  templateUrl: './toolbar-seller.component.html',
  styleUrls: ['./toolbar-seller.component.scss'],
})
export class ToolbarSellerComponent implements OnInit {
  private api_url = AppSettings.API_ENDPOINT;
  private merchant_id;
  private customer_id;
  sessionInfo: any;
  count : any;
  private refreshSubscription: Subscription;
  constructor(
    private router: Router,
    private http: HttpClient,
    private sessionInfoService: SessionInfoServiceService,
    private toolbarService: UpdateUnreadChatService,
    private updateUnreadChatService: UpdateUnreadChatService
  ) { }

  async ngOnInit() {
    this.sessionInfo = await this.sessionInfoService.getSessionInfo();
    if (this.sessionInfo) {
      this.customer_id = this.sessionInfo["id"];
      this.merchant_id = this.sessionInfo["merchant_id"];
    } else {
      console.error('Session info is undefined.');
    }
    this.receiveInformation();
    this.refreshSubscription = this.toolbarService.getRefreshObserver().subscribe(() => {
      this.receiveInformation();
    });

  }

  receiveInformation(){
    let requestPayload = {
      merchant_id: this.merchant_id,
      // merchant_id: this.merchant_id,
      sender: "customer",

    };

    this.http.post(this.api_url+'chat-info/get-receive-information', JSON.stringify(requestPayload)).subscribe(
      data=> {

        console.log('receiveInformation',data);
        this.count = data["data"];
        console.log('this.count1',this.count);
      },
      err=>{
        console.log(err);
        // this.loaderService.closeLoader();
        // this.errorAlert.presentAlert("Couldn't send message")
      }
    );}



  ngOnDestroy() {
    // 取消订阅
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }


  //  openHomepage(){
  //    this.receiveInformation();
  //   this.router.navigate(['/seller/homepage']);
  // }
  async openHomepage(){
    //this.router.navigate(['/homepage-new'])
    await this.router.navigate(['/seller/homepage'], {replaceUrl: true});
  }
  // openForum(){
  //   this.router.navigate(['/seller/forum']);
  // }
  async openForum(){
    //this.router.navigate(['./forum'])
    await this.router.navigate(['/seller/forum'], {replaceUrl: true});
  }

  async openChatroom(){
    //this.router.navigate(['/chatroom'])
    await this.router.navigate(['seller/chatroom'], {replaceUrl: true});
  }

  async openAddProduct(){
    //this.router.navigate(['/shopping-cart'])
    await this.router.navigate(['/product-category'], {replaceUrl: true});
  }

  async openSellerProfile(){
    //this.router.navigate(['/profile'])
    await this.router.navigate(['seller/profile'], {replaceUrl: true});
  }

  // openChatroom(){
  //   this.router.navigate(['seller/chatroom']);
  // }
  //
  // openAddProduct(){
  //   this.router.navigate(['/product-category']);
  // }
  //
  // openSellerProfile(){
  //   this.router.navigate(['seller/profile']);
  // }

}
