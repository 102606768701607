import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/appSettings';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import { SessionInfoServiceService } from 'src/app/services/session-info-service/session-info-service.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-toolbar-shop-page',
  templateUrl: './toolbar-shop-page.component.html',
  styleUrls: ['./toolbar-shop-page.component.scss'],
})
export class ToolbarShopPageComponent implements OnInit {
  @Input() merchant_id;
  shop_info;
  private API_ENDPOINT=AppSettings.API_ENDPOINT;
  private session_id;
  private session_info;
  public isFollowing:boolean;
  private api_url_getfollows = AppSettings.API_ENDPOINT + 'merchant-info/get-follows';
  private followed_merchants = [];
  private enableChat: boolean;

  constructor(
    private router: Router,
    private http: HttpClient,
    private sessionInfoService: SessionInfoServiceService,
    public alertController: AlertController
  ) { }

  ngOnInit() {
    this.sessionInfoService.getSessionInfo().then((session_info) => {
      this.session_info = session_info;
      this.session_id = session_info['session_id'];
      this.getMerchantInformation();
      this.getFollowedShop(this.session_id);
      console.log("isFollowing: ", this.isFollowing);
    });
  }

  async openHomePage(){

    await this.router.navigate(['/buyer/shop-homepage'], {state: {merchant_id: this.merchant_id}})
  }

  async openShopCategory(){
    await this.router.navigate(['/buyer/shop-category'], {state: {merchant_id: this.merchant_id, previous_url: this.router.url}})
  }

  async openShopAllProducts(){
    console.log("Data going in", this.merchant_id, this.router.url)
    await this.router.navigate(['/buyer/shop-all-products'], {state: {merchant_id: this.merchant_id, previous_url: this.router.url}})
  }


  //getting followed shop lists
  getFollowedShop(session_id: string) {
    const body = {
      session_id: session_id
    }
    this.http.post(this.api_url_getfollows, JSON.stringify(body)
    ).subscribe((data: any) => {
      if (data['status'] == '200'){
        this.followed_merchants = data["data"]
        this.isFollowing = this.isFollowed();
      }
    });
    // return this.followed_merchants;

  }

  // removeDuplicateShop(){
  //   //remove duplicate shops from followed_merchants list
  //   //this.followed_merchants = this.followed_merchants.filter((v,i,a)=>a.findIndex(t=>(t.merchant_id === v.merchant_id))===i)

  // }

  isFollowed(){
    //write the function to check if user is following the shop
    //if yes, return true
    //if no, return false
    let stat = false;
    for(let i=0; i<this.followed_merchants.length; i++){
      console.log(this.followed_merchants[i]['merchant_id']);
      if(this.followed_merchants[i]['merchant_id'] == this.merchant_id){
        stat = true;
        console.log("FOUND IT");
      }
    }
    return stat;

  }

  followStore(){
    this.isFollowing = this.isFollowed();

    if(this.isFollowing == true){
      console.log("BUG BUG BUG YOU ARE NOW UNFOLLOWED");
      this.isFollowing = false;
      let headers = new HttpHeaders();
      headers.append("Content Type", 'multipart/form-data');
      headers.append("Accept", 'application/json');

      let body = {
        merchant_id: this.merchant_id,
        session_id: this.session_id
      }

      this.http.post(this.API_ENDPOINT+"merchant-info/unfollow-merchant", JSON.stringify(body), {headers: headers}).subscribe(
        data=>{
          console.log(data);
          this.getFollowedShop(this.session_id);
          // I don't think we need this anymore
          //this.presentAlertConfirm();
        },
        error=>{
          console.log(error);
        }
      );
    }
    else{
      // this.http.get({url:})
      console.log("BUG BUG BUG YOU ARE NOW FOLLOWING")
      this.isFollowing = true;
      let headers = new HttpHeaders();
      headers.append("Content Type", 'multipart/form-data');
      headers.append("Accept", 'application/json');

      let body = {
        merchant_id: this.merchant_id,
        session_id: this.session_id
      }

      this.http.post(this.API_ENDPOINT+"merchant-info/follow-merchant", JSON.stringify(body), {headers: headers}).subscribe(
        data=>{
          console.log(data);
          this.getFollowedShop(this.session_id);
          // I don't think we need this anymore
          // this.presentAlertConfirm();
        },
        error=>{
          console.log(error);
        }
      );

      }
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: '成功',
      message: '已關注商店',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('OK clicked');
          }
        }
      ]
    });

    await alert.present();
  }

  // getMerchantInformation(){
  //   this.http.get(this.API_ENDPOINT+'merchant-info/single-merchant/'+this.merchant_id).subscribe(
  //     data=>{
  //       this.shop_info=data['data'];
  //       if (this.shop_info?.settings === '' || this.shop_info?.settings === undefined) {
  //         this.enableChat = true;
  //       } else {
  //         this.enableChat = this.shop_info?.settings.split(',')[0] === 'true';
  //       }
  //     },
  //     error=>{
  //       console.log(error);
  //     }
  //   );
  // }
  getMerchantInformation() {
    this.http.get(this.API_ENDPOINT + 'merchant-info/single-merchant/' + this.merchant_id).subscribe(
      data => {
        this.shop_info = data['data'];
        console.log("this.shop_info?.settings = ",this.shop_info?.settings);
        if (this.shop_info?.settings === '' || this.shop_info?.settings === undefined) {
          this.enableChat = true;
        } else {
          const settings = this.shop_info.settings;
          if (settings !== null && settings !== undefined) {
            this.enableChat = settings.split(',')[0] === 'true';
          } else {
            this.enableChat = false; // 或者设置默认值
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  async startChat(){
    console.log("this.enableChat",this.enableChat);
    if (this.enableChat) {
      let body = {
        merchant_id: this.merchant_id,
        customer_user_id: this.session_info['id']
      };

      this.http.post(this.API_ENDPOINT+'chat-info/start-chat', JSON.stringify(body)).subscribe(
        res=>{
          console.log(res);
          if (res["status"] == 200)
            this.router.navigate(['/chatroom-page'], {state:
                {
                  merchant_id: this.merchant_id,
                  customer_user_id: this.session_info['id'],
                  previous_url: this.router.url
                }
            });
        },
        error=>{
          console.log(error);
        }
      );
    } else {
      alert('商家已關閉聊天室功能');
    }
  }

}
