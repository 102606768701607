import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/appSettings';
import { ErrorAlertService } from '../error-alert/error-alert.service';
import { LoaderServiceService } from '../loader-service/loader-service.service';
import { SessionInfoServiceService } from '../session-info-service/session-info-service.service';
import { StorageService } from '../storage-service/storage.service';

interface productInfo {
  product_id: string;
  session_id: string;
  merchant_id: string;
  first_category: string;
  second_category: string;
  name: string;
  price: number;
  discount: number;
  stock: number;
  length: number;
  height: number;
  width: number;
  weight: number;
  description: string;
  media: string;
  updateMedia: string[];
  needDeleteS3Url: string[];
  f2f: string;
  parcel: string;
  express: string;
  pickup: string;
  store_arrange: string;
  delivery_fee: string;
  is_sh: boolean;
  is_organism: number;
  hashtag: string;
  type_id: string;
}


@Injectable({
  providedIn: 'root'
})

export class ProductCreateService {
  private product_info: productInfo;
  public previews: any[] = []; // 用于存储文件的预览信息
  public needDeleteS3Url: string[] = []; // 用于存储文件的预览信息
  private state;
  private first_category;
  private product_detail;
  private description;
  private image_list;
  private delivery_methods;
  private delivery_fee;
  private api_endpoint=AppSettings.API_ENDPOINT;
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private sessionInfoService: SessionInfoServiceService,
    private loaderService: LoaderServiceService,
    private errorAlertService: ErrorAlertService,
    private router: Router
  ) {
      // initialize product_info object
      this.product_info = {
        session_id: '',
        merchant_id: '',
        first_category: '',
        second_category: '',
        name: '',
        price: 0,
        discount: 0,
        stock: 0,
        length: 0,
        height: 0,
        width: 0,
        weight: 0,
        description: '',
        media: '',
        updateMedia:[],
        needDeleteS3Url:[],
        f2f: '',
        parcel: '',
        express: '',
        pickup: '',
        store_arrange: '',
        delivery_fee: '',
        is_sh: false,
        is_organism: 0,
        hashtag: '',
        type_id: '',
        product_id: ''
      };

  }


  setState(state){
    this.state=state;
    // console.log("Test")
  }



  // 稳定版本
  // async uploadAllFiles() {
  //   console.log(this.previews);
  //
  //
  //   for (let preview of this.previews) {
  //     const fileUrl = await this.uploadFileInChunks(preview.file);
  //     console.log("fileUrl->>",fileUrl);
  //     preview.fileUrl = fileUrl; // 保存文件本地的URL
  //   }
  //   console.log("this.previews",this.previews);
  // }

  async uploadAllFiles() {
    console.log(this.previews);

    const links = [];

    this.previews = this.previews.filter(preview => {
      if (typeof preview === 'string' && preview.startsWith("http")) {
        links.push(preview);
        return false;
      }
      return true;
    });
    if (links.length > 0) {
      this.product_info.updateMedia = links;
    }
    const uploadPromises = this.previews.map(async preview => {
      if (preview && typeof preview === 'object' && preview.file) {
        const fileUrl = await this.uploadFileInChunks(preview.file);
        console.log("fileUrl->>", fileUrl);
        preview.fileUrl = fileUrl;
      }
    });
    await Promise.all(uploadPromises);

    console.log("Filtered this.previews", this.previews);
    console.log("this.product_info.updateMedia", this.product_info.updateMedia);
  }
  async uploadFileInChunks(file: File): Promise<string> {
    if (file.size <= 5 * 1024 * 1024) { // 文件小于等于 5MB
      console.log('文件小于5MB，直接上传');
      const completeData = new FormData();
      completeData.append('fileName', file.name);
      completeData.append('action', 'complete');
      completeData.append('totalChunks', file.size.toString());
      completeData.append('type', '1');
      completeData.append('file', file);


      const response: any = await this.http.post(AppSettings.API_ENDPOINT+'product-info/block', completeData).toPromise();
      console.log(response.fileUrl);
      return response.fileUrl; // 假设后端返回文件的URL
    } else {
      console.log('文件大于5MB，分块上传');
      const chunkSize = 5 * 1024 * 1024; // 每个分块的大小，5MB
      const totalChunks = Math.ceil(file.size / chunkSize);
      console.log("totalChunks11 -》》》",totalChunks);
      for (let i = 0; i < totalChunks; i++) {
        const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('fileName', file.name);
        formData.append('chunkNumber', i.toString());
        formData.append('totalChunks', totalChunks.toString());
        formData.append('type', '2');

        console.log('totalChunks222:', formData.get('totalChunks'));
        // 发送每个分块到后端
        await this.http.post(AppSettings.API_ENDPOINT+'product-info/block', formData).toPromise();
      }
      console.log("上传完成 -》》》");

      // 通知后端所有分块已发送完毕，并获取文件的URL
      const completeData = new FormData();
      completeData.append('fileName', file.name);
      completeData.append('action', 'complete');
      completeData.append('totalChunks', totalChunks.toString());
      completeData.append('type', '2');

      const response: any = await this.http.post(AppSettings.API_ENDPOINT+'product-info/block', completeData).toPromise();
      console.log("response --->>>>>",response);
      return response.fileUrl; // 假设后端返回文件的URL
    }

  }
 // async postToServer(type, productId){
 //    this.sessionInfoService.getSessionInfo().then(async (session_info) => {
 //        const sessionInfo = session_info;
 //        this.product_info.session_id = sessionInfo['session_id'];
 //        this.product_info.merchant_id = sessionInfo['merchant_id'];
 //        if (productId) {
 //          this.product_info.product_id = productId;
 //        }
 //
 //        await this.uploadAllFiles();
 //
 //        const completeData = new FormData();
 //        let fileUrls = this.previews.map(preview => preview.fileUrl);
 //        let fileUrlsJson = JSON.stringify(fileUrls);
 //        this.product_info.media = fileUrlsJson;
 //        console.log("fileUrlsJson", fileUrlsJson);
 //        console.log(this.product_info);
 //        console.log("create",type);
 //        this.loaderService.basicLoader("Please wait").then(() => {
 //            const editOrCreate = (type === 'create' || type === 'duplicate') ? 'product-info/create-new-product' : 'product-info/update-product';
 //            this.http.post(this.api_endpoint + editOrCreate, JSON.stringify(this.product_info)).subscribe(
 //              res => {
 //                //Popup Open - Please wait
 //                if (res["status"] == '200') {
 //                  this.loaderService.closeLoader();
 //                  this.state["show_success"] = true;
 //                  // clear product_info object
 //                  this.product_info = {
 //                    session_id: '',
 //                    merchant_id: '',
 //                    first_category: '',
 //                    second_category: '',
 //                    name: '',
 //                    price: 0,
 //                    discount: 0,
 //                    stock: 0,
 //                    length: 0,
 //                    height: 0,
 //                    width: 0,
 //                    weight: 0,
 //                    description: '',
 //                    media: '',
 //                    f2f: '',
 //                    parcel: '',
 //                    express: '',
 //                    pickup: '',
 //                    store_arrange: '',
 //                    delivery_fee: '',
 //                    is_sh: false,
 //                    is_organism: 0,
 //                    hashtag: '',
 //                    type_id: '',
 //                    product_id: ''
 //                  };
 //                  console.log(res);
 //                } else {
 //                  console.log(res);
 //                  this.loaderService.closeLoader();
 //                  this.errorAlertService.presentAlert("Server failure, try again later!")
 //                  this.router.navigate(['/add-product-delivery'])
 //                }
 //              },
 //              err => {
 //                console.log(err);
 //                this.loaderService.closeLoader();
 //                this.errorAlertService.presentAlert("Server failure, try again later!")
 //                this.router.navigate(['/add-product-delivery'])
 //              }
 //            )
 //          },
 //          err => {
 //            console.log(err);
 //            this.loaderService.closeLoader();
 //            this.errorAlertService.presentAlert("Server failure, try again later!")
 //          }
 //        )
 //      }
 //
 //    )
 //  }



    async postToServer(type, productId) {
        this.sessionInfoService.getSessionInfo().then(async (session_info) => {
            const sessionInfo = session_info;
            this.product_info.session_id = sessionInfo['session_id'];
            this.product_info.merchant_id = sessionInfo['merchant_id'];
            if (productId) {
                this.product_info.product_id = productId;
            }
            const loading = await this.loaderService.basicLoader("請等待,文件上傳中...");
            try {
                await this.uploadAllFiles();
                let fileUrls = this.previews.map(preview => preview.fileUrl);
                let fileUrlsJson = JSON.stringify(fileUrls);
                this.product_info.media = fileUrlsJson;
                console.log("fileUrlsJson", fileUrlsJson);
                console.log(this.product_info);
                console.log("updateMedia1111",this.product_info.updateMedia);
                this.product_info.needDeleteS3Url=this.needDeleteS3Url;
                console.log("needDeleteS3Url11111",this.product_info.needDeleteS3Url);
                console.log("create", type);

                const editOrCreate = (type === 'create' || type === 'duplicate') ? 'product-info/create-new-product' : 'product-info/update-product';
                this.http.post(this.api_endpoint + editOrCreate, JSON.stringify(this.product_info)).subscribe(
                    res => {
                        // 处理成功响应
                        if (res["status"] == '200') {
                            this.loaderService.closeLoader(); // 关闭加载器
                            this.state["show_success"] = true;
                            // 清空 product_info 对象
                            this.product_info = {
                                session_id: '',
                                merchant_id: '',
                                first_category: '',
                                second_category: '',
                                name: '',
                                price: 0,
                                discount: 0,
                                stock: 0,
                                length: 0,
                                height: 0,
                                width: 0,
                                weight: 0,
                                description: '',
                                media: '',
                                updateMedia:[],
                                needDeleteS3Url:[],
                                f2f: '',
                                parcel: '',
                                express: '',
                                pickup: '',
                                store_arrange: '',
                                delivery_fee: '',
                                is_sh: false,
                                is_organism: 0,
                                hashtag: '',
                                type_id: '',
                                product_id: ''
                            };
                            console.log(res);
                        } else {
                            console.log(res);
                            this.loaderService.closeLoader();
                            this.errorAlertService.presentAlert("Server failure, try again later!");
                            this.router.navigate(['/add-product-delivery']);
                        }
                    },
                    err => {
                        console.log(err);
                        this.loaderService.closeLoader();
                        this.errorAlertService.presentAlert("Server failure, try again later!");
                        this.router.navigate(['/add-product-delivery']);
                    }
                );
            } catch (error) {
                console.error("Error uploading files:", error);
                this.loaderService.closeLoader();
                this.errorAlertService.presentAlert("文件上传失败，请稍后再试！");
            } finally {
                this.loaderService.closeLoader();
            }
        });
    }

  updateProductInfo(info, value){
    console.log("previews文件",this.previews);
     this.product_info[info] = value;
     console.log("Product Info Updated", this.product_info);
  }


  private productClickArray = [];

  updateProductClick(product_id) {
    let foundProduct = false;

    for (let i = 0; i < this.productClickArray.length; i++) {
      if (this.productClickArray[i].product_id === product_id) {
        this.productClickArray[i].clicks++;
        foundProduct = true;
        break;
      }
    }

    if (!foundProduct) {
      let productClicked = {
        product_id: product_id,
        clicks: 1
      };
      this.productClickArray.push(productClicked);
    }

    console.log("Product Click Array is - ", this.productClickArray);

    if (this.productClickArray.length >= 1) {
      this.postProductVisitRateToServer();
    }
  }

  postProductVisitRateToServer() {
    let payload = {
      data: this.productClickArray
    };

    this.http.post(this.api_endpoint + "/product-info/save-product-visit-rate", JSON.stringify(payload)).subscribe(
      response => {
        if (response["status"] == 200){
          console.log("Product click successfully saved, clearing product save array");
          this.productClickArray = []; // Empty the array after a successful request
        }
      },
      error => {
        console.error("Batch request failed");
      }
    );
  }

private storeClickArray = [];

  updateStoreClick(merchant_id) {
    let foundStore = false;

    for (let i = 0; i < this.storeClickArray.length; i++) {
      if (this.storeClickArray[i].merchant_id === merchant_id) {
        this.storeClickArray[i].clicks++;
        foundStore = true;
        break;
      }
    }

    if (!foundStore) {
      let storeClicked = {
        merchant_id: merchant_id,
        clicks: 1
      };
      this.storeClickArray.push(storeClicked);
    }

    console.log("Store Click Array is - ", this.storeClickArray);

    if (this.storeClickArray.length >= 1 ) {
      this.postStoreVisitRateToServer();
    }
  }

  postStoreVisitRateToServer() {
    let payload = {
      data: this.storeClickArray
    };

    this.http.post(this.api_endpoint + "/product-info/save-store-visit-rate", JSON.stringify(payload)).subscribe(
      response => {
        if (response["status"] == 200){
          console.log("Store click successfully saved, clearing store save array");
          this.storeClickArray = []; // Empty the array after a successful request
        }
      },
      error => {
        console.error("Batch request failed");
      }
    );
  }
}
