import { Injectable } from '@angular/core';
import { StorageService } from '../storage-service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private isMerchant;
  constructor(
    private storage: StorageService,
    ) {}
  getUser(){
    return this.isMerchant;
  }
  setUser(user){
    this.isMerchant=user.is_merchant;
  }

  async saveUserProfile(data: any) {
    await this.storage.setObject('userProfile', data);
  }

  getUserProfile() {
    return this.storage.getObject('userProfile');
  }
}
