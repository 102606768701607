import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionInfoServiceService } from 'src/app/services/session-info-service/session-info-service.service';

@Component({
  selector: 'app-toolbar-seller-top',
  templateUrl: './toolbar-seller-top.component.html',
  styleUrls: ['./toolbar-seller-top.component.scss'],
})
export class ToolbarSellerTopComponent implements OnInit {

  private session_info;
  private session_id;
  private merchant_id;

  constructor(
    private router: Router,
    private sessionInfoService: SessionInfoServiceService
  ) { }

  ngOnInit() {
    this.sessionInfoService.getSessionInfo().then((session_info) => {
      this.session_info = session_info;
      this.session_id = session_info['session_id'];    
      this.merchant_id = session_info['merchant_id']
    });
  }

  async openShopCategory(){
    await this.router.navigate(['/seller/shop-category'], {state: {merchant_id: this.merchant_id, previous_url: this.router.url}})
  }

  async openShopAllProducts(){
    console.log("Data going in", this.merchant_id, this.router.url)
    await this.router.navigate(['/seller/shop-all-products'], {state: {merchant_id: this.merchant_id, previous_url: this.router.url}})
  }
}
