import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() icon_src_left;
  @Input() icon_src_center;
  @Input() title;
  @Input() icon_src_right;
  @Input()showSearchbar;

  @Output() iconClick: EventEmitter<void> = new EventEmitter<void>();

  onClick() {
    this.iconClick.emit();
  }


  constructor() { }

  ngOnInit() {}

}
