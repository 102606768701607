import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductInfoService {

  productInfo = {};


  constructor() {}

  setProductInfo(data){
    this.productInfo = data;
    console.log("Set Product Info :", data);
  }


  getProductInfo(){
    console.log("Get Product Info: ", this.productInfo);
    return this.productInfo;
  }}
