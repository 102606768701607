import { IonicModule } from '@ionic/angular';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { MerchantOrdersComponent } from './merchant-orders/merchant-orders.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProductCardComponent } from './product-card/product-card.component';
import { PostCardComponent } from './post/post-card/post-card.component';
import { PostCommentCardComponent } from './post/post-card/post-comment-card/post-comment-card.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ToolbarSellerComponent } from './toolbar-seller/toolbar-seller.component';
import { ToolbarSellerTopComponent } from './toolbar-seller-top/toolbar-seller-top.component';
import { ToolbarShopPageComponent } from './toolbar-shop-page/toolbar-shop-page.component';
import { ToolbarBuyerComponent } from './toolbar-buyer/toolbar-buyer.component';
import { ImageModalComponent } from '../modals/image-modal/image-modal.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [
    ProductCardComponent,
    PostCardComponent,
    PostCommentCardComponent,
    SearchBarComponent,
    ToolbarSellerComponent,
    ToolbarSellerTopComponent,
    ToolbarShopPageComponent,
    ToolbarBuyerComponent,
    ImageModalComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    IonicModule.forRoot(),

    FormsModule,
    ReactiveFormsModule,

  ],
  exports: [
    ProductCardComponent,
    PostCardComponent,
    PostCommentCardComponent,
    SearchBarComponent,
    ToolbarSellerComponent,
    ToolbarSellerTopComponent,
    ToolbarShopPageComponent,
    ToolbarBuyerComponent,
    ImageModalComponent,
    HeaderComponent
  ]
})

export class ComponentsModule { }

