import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchResultService } from 'src/app/services/search-service/search-result.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() searchWord;
  public searchInput: string;

  @Output()
  searchClicked: EventEmitter<String> = new EventEmitter<String>();

  constructor(
    private router: Router,
    private searchResultService: SearchResultService,
    // private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.searchInput = this.searchWord;
    this.searchClicked.emit(this.searchInput);
    // console.log(    this.searchClicked.emit(this.searchInput)    )
  }

  async showSearchResult(){
    if (this.searchInput) {
      const encodedInput = encodeURIComponent(this.searchInput);
      await this.searchResultService.setSearchResult(encodedInput);
      await this.searchResultService.setShopResult(encodedInput).then(
        res=>{
          this.router.navigate(["/search-page"], {state:{searchInput: this.searchInput}}).then(()=>{this.searchClicked.emit(this.searchInput);});
        },
        err => {
          console.log(err);
        });
    }
  }

}
