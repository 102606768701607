import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppSettings} from 'src/appSettings';

// import { EventEmitter } from 'stream';

@Injectable({
  providedIn: 'root'
})
export class SearchResultService {
  private api_endpoint = AppSettings.API_ENDPOINT;

  searchResult = [];
  shopResult = [];

  constructor(
    private http: HttpClient,
  ) {}

  async setSearchResult(searchInput){
    var result=[];
    let searchPromise=new Promise((res, rej)=>{
      this.http.get(this.api_endpoint+"product-info/search-product/"+searchInput)
    // console.log(result);
      .subscribe(
        data => {
          console.log(data);
          for (var i of data["data"]){
            if (i['stock'] !== '0'){
              // Documentation: JSON object from API call -> media: "['url']"
              // To parse into an array format must be -> media: "["url"]"
              let media = JSON.parse(i.media.replace(/'/g, '"'));
              // Pushing the new media value into the JSON object
              i.media = media;
              result.push(i);
              // return true;
            }
          }
          this.searchResult=result;
          // console.log(this.searchResult);
          res(true);
          // return true;
        },
        error=> {
          console.log(error);
          // return false;
          rej(false);
        }
      )
    })

    return searchPromise;
  }

  async setShopResult(searchInput){
    var result=[];
    return new Promise((res, rej) => {
      this.http.get(this.api_endpoint + 'merchant-info/search-merchant/' + searchInput)
        .subscribe(
          data => {
            console.log(data);
            for (var i of data['data']) {
              result.push(i);
            }
            this.shopResult = result;
            res(true);
          },
          error => {
            console.log(error);
            rej(false);
          }
        );
    });
  }
    // console.log(this.searchResult)

  async getSearchResult(){
    // console.log(this.searchResult);
    return this.searchResult;
  }

  async getShopResult(){
    return this.shopResult;
  }
}

